require('owl.carousel')

$(document).ready(function(){
	$('#owl-carousel-weather').owlCarousel({
		center:true,
	    loop:true,
	    margin:15,
	    autoplay:false,
	    dots:false,
	    dotsEach:2,
	    responsiveClass:true,
	    nav:true,
	    navText:['<i class="fas fa-angle-left"></i>','<i class="fas fa-angle-right"></i>'],
	    responsive:{
	        0:{
	            items:2
	        },
	        768:{
	            items:5
	        },
	        1200:{
	            items:6
	        },
	        1600:{
	            items:7
	        }
	    }
	})
});

$(document).ready(function(){
	$('#owl-carousel-comments').owlCarousel({
	    loop:true,
	    margin:15,
	    autoplay:true,
	    dots:true,
	    dotsEach:2,
	    responsiveClass:true,
	    nav:true,
	    navText:['<i class="fas fa-angle-left"></i>','<i class="fas fa-angle-right"></i>'],
	    responsive:{
	        0:{
	            items:1
	        },
	        768:{
	            items:2
	        },
	        1200:{
	            items:3
	        },
	        1600:{
	            items:3
	        }
	    }
	})
});